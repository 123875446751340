<template>
  <div id="app">
    <header id="header" class="w-full text-white bg-white">
      <div class="w-full container mx-auto mt-2 mb-2 md:mb-4 pt-12 md:pt-0">
        <div class="flex flex-wrap text-black mt-0 md:mt-0">

          <div v-if="ingNav" class="flex-auto">
            <img class="ml-2 h-16 lg:h-full" src="./assets/gfx/logo_ing.png" alt="">
          </div>

          <div v-else class="flex-auto">
            <img class="ml-2 h-16 lg:h-full" src="./assets/gfx/logo_heizungsservice.png" alt="">
          </div>


          <div v-if="ingNav" class="hidden lg:inline-flex flex-auto justify-end mr-2">
            <!-- Won't grow, but will shrink if needed -->
            <router-link to="/"><img class="h-10 lg:h-16" src="./assets/gfx/logo_heizungsservice_kl.png"
                alt=""></router-link>
          </div>

          <div v-else class="hidden lg:inline-flex flex-auto justify-end mr-2">
            <!-- Won't grow, but will shrink if needed -->
            <router-link to="/ingenieurbuero"><img class="h-10 lg:h-16" src="./assets/gfx/logo_ing_kl.png"
                alt=""></router-link>
          </div>

        </div>
      </div>

      <div class="w-full flex flex-wrap items-center justify-between bg-white"
        v-bind:class="{ 'fixed top-0 z-50': isSticky }" style="z-index:999">
        <div class="container mx-auto" id="fixit">

          <!-- Desktop Navi Heizung -->
          <div class="hidden md:block">
            <nav class="navbg" :class="theme">
              <div class="flex flex-row">
                <div class="flex flex-wrap">
                  <!-- Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" -->
                  <router-link v-if="!ingNav" to="/"
                    class="hover:text-white px-6 py-2 text-xl font-medium">Service</router-link>
                  <router-link v-if="!ingNav" to="/about" class="hover:text-white px-5 py-2 text-xl font-medium">Wir
                    über uns</router-link>
                  <router-link v-if="!ingNav" to="/kontakt"
                    class="hover:text-white px-5 py-2 text-xl font-medium">Kontakt</router-link>

                  <router-link v-if="!ingNav" to="/impressum"
                    class="hover:text-white px-5 py-2 text-xl font-medium">Impressum</router-link>

                  <router-link v-if="ingNav" to="/ingenieurbuero"
                    class="hover:text-white px-5 py-2 text-xl font-medium">Unsere Leistungen</router-link>
                  <router-link v-if="ingNav" to="/ingenieurbuero/impressum"
                    class="hover:text-white px-5 py-2 text-xl font-medium">Impressum</router-link>
                </div>

                <div class="flex flex-grow justify-center space-x-4">
                  <router-link v-if="!ingNav" to="/ingenieurbuero"
                    class="bg-ld hover:bg-ldover hover:text-white px-5 py-2 text-xl font-medium">Ingenieurbüro</router-link>
                  <router-link v-if="ingNav" to="/"
                    class="hover:text-white px-5 py-2 text-xl font-medium">Gasheizungsservice</router-link>
                </div>
              </div>
            </nav>
          </div>
          <!-- Desktop Navi -->



        </div>
      </div>


      <!-- Mobile Navi -->
      <div class="block md:hidden py-2 fixed top-0 w-full" :class="theme" style="z-index:999;">
        <div class="pl-2">
          <button type="button" class="block text-gray-500 hover:text-white focus:text-white focus:outline-none"
            @click="isOpen = !isOpen">
            <svg class="h-6 w-6 fill-current text-white" viewBox="0 0 24 24">
              <path v-if="isOpen" fill-rule="evenodd"
                d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z" />
              <path v-if="!isOpen" fill-rule="evenodd"
                d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z" />
            </svg>
          </button>
        </div>

        <nav class="pt-2 md:flex sm:p-0" :class="isOpen ? 'block' : 'hidden'">
          <router-link v-if="!ingNav" to="/"
            class="block px-2 py-1 text-white font-semibold rounded hover:ldover sm:ml-2"><span
              v-on:click="isOpen = !isOpen">Service</span></router-link>
          <router-link v-if="!ingNav" to="/about"
            class="mt-1 block px-2 py-1 text-white font-semibold rounded hover:ldover sm:mt-0 sm:ml-2"><span
              v-on:click="isOpen = !isOpen">Wir über uns</span></router-link>
          <router-link v-if="!ingNav" to="/kontakt"
            class="mt-1 block px-2 py-1 text-white font-semibold rounded hover:ldover sm:mt-0 sm:ml-2"><span
              v-on:click="isOpen = !isOpen">Kontakt</span></router-link>
          <router-link v-if="!ingNav" to="/ingenieurbuero"
            class="mt-1 block px-2 py-1 text-white font-semibold rounded hover:ldover sm:mt-0 sm:ml-2"><span
              v-on:click="isOpen = !isOpen">Ingenieurbüro</span></router-link>
          <router-link v-if="!ingNav" to="/impressum"
            class="mt-1 block px-2 py-1 text-white font-semibold rounded hover:ldover sm:mt-0 sm:ml-2"><span
              v-on:click="isOpen = !isOpen">Impressum</span></router-link>

          <router-link v-if="ingNav" to="/ingenieurbuero"
            class="mt-1 block px-2 py-1 text-white font-semibold rounded hover:ldover sm:mt-0 sm:ml-2"><span
              v-on:click="isOpen = !isOpen">Unsere Leistungen</span></router-link>
          <router-link v-if="ingNav" to="/ingenieurbuero/impressum"
            class="mt-1 block px-2 py-1 text-white font-semibold rounded hover:ldover sm:mt-0 sm:ml-2"><span
              v-on:click="isOpen = !isOpen">Impressum</span></router-link>
          <router-link v-if="ingNav" to="/"
            class="mt-1 block px-2 py-1 text-white font-semibold rounded hover:ldover sm:mt-0 sm:ml-2"><span
              v-on:click="isOpen = !isOpen">Gasheizungsservice</span></router-link>
        </nav>
      </div>
    </header>
    <div class="w-full container mx-auto items-center justify-between text-black pt-0 md:pt-0 lg:pt-0">
      <router-view />

      <div class="mt-4 lg:mt-4 mb-10 px-3 pt-4">

        <hr class="style2">

        <ul>
          <li class="inline-block"><router-link v-if="!ingNav" to="/impressum">Impressum</router-link></li>
          <li class="inline-block"><router-link v-if="ingNav" to="/ingenieurbuero/impressum">Impressum</router-link>
          </li>
          <li class="inline-block ml-4"><router-link v-if="!ingNav" to="/datenschutz">Datenschutz</router-link></li>
          <li class="inline-block ml-4"><router-link v-if="ingNav"
              to="/ingenieurbuero/datenschutz">Datenschutz</router-link></li>
          <li class="inline-block"><router-link v-if="!ingNav" to="/preisliste">Preisliste</router-link></li>

        </ul>
      </div>
    </div><!-- container -->


  </div>
</template>

<style lang="scss">
@font-face {
  font-family: 'PT Sans';
  font-style: normal;
  font-weight: 400;
  src: url('~@/assets/fonts/pt-sans-v9-latin-regular.eot');
  /* IE9 Compat Modes */
  src: local('PT Sans'), local('PTSans-Regular'),
    url('~@/assets/fonts/pt-sans-v9-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('~@/assets/fonts/pt-sans-v9-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('~@/assets/fonts/pt-sans-v9-latin-regular.woff') format('woff'),
    /* Modern Browsers */
    url('~@/assets/fonts/pt-sans-v9-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('~@/assets/fonts/pt-sans-v9-latin-regular.svg#PTSans') format('svg');
  /* Legacy iOS */
}


.red {
  line-height: 1.5;
  letter-spacing: 0em;
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: #ed1c24;
  background-color: #ed1c24;
  flex-wrap: wrap;
}

.red a {
  border-width: 0;
  border-style: solid;
  border-color: #e5e7eb;
  text-decoration: inherit;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.75rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  background-color: #ed1c24;
}

.green {
  line-height: 1.5;
  letter-spacing: 0em;
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: #ed1c24;
  background-color: #98bf21;
  flex-wrap: wrap;
}


#fixit {
  a {
    &.router-link-exact-active {
      background-color: #AD151A;
    }
  }
}


#fixit .green {
  a {
    &.router-link-exact-active {
      background-color: #79981a;
    }
  }
}


#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: blue;
    }
  }
}
</style>


<script>
export default {
  data() {
    return {
      isSticky: false,
      stickyClass: 'is_sticky',
      scrollPosition: 0,
      isOpen: false,
      ingNav: false,
      theme: 'red',

    }
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      this.scrollPosition = window.scrollY
      let navBar = document.getElementById('fixit');
      if (this.scrollPosition > navBar.offsetTop) {
        this.isSticky = true
        // alert("scroll")
      } else {
        this.isSticky = false
        //  alert("Scroll ende")
      }
    },

  },
  watch: {
    '$route'() {
      if (this.$route.path === '/ingenieurbuero' || this.$route.path === '/ingenieurbuero/impressum' || this.$route.path === '/ingenieurbuero/datenschutz') {
        this.ingNav = true;
        this.theme = 'green'
      }
      else {
        this.ingNav = false;
        this.theme = 'red'
      }
    },
  }
}
</script>