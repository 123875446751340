<template>
  <div class="danke">
   
    <!-- Section Danke -->
  <section class="mt-4 lg:mt-12 px-3">
   
      <h3 class="text-xl md:text-2xl lg:text-4xl">Vielen Dank</h3>
      <div class="horizontal-divider"></div>

    <div class="flex flex-wrap overflow-hidden lg:mt-8">
      <div class="w-full lg:pr-12 overflow-hidden content text-base md:text-xl lg:text-2xl">
      Die Daten wurden übermittelt.


      </div>
    </div>

</section>
 
  </div>
</template>


<style lang="css">

.danke {
    font-family: 'PT Sans', sans-serif;
    font-size: 14px;
  }

.danke p {
    color: #444;
    padding: 0;
    border: 0;
    font-size: 1.5rem;
    font: inherit;
    margin: 0 0 20px 0;
}

</style>


<script>


export default {
  name: 'Danke',
}
</script>
