<template>
  <div class="home">

   <div>
    <img src="../assets/images/heizung_banner.jpg" alt="Energiekosten senken mit regelmäßiger Wartung und Kundendienst" />
   </div>

  <!-- Section Willkommen -->
  <section class="mt-4 lg:mt-12 px-3">
   
      <h3 class="text-xl md:text-2xl lg:text-4xl">Herzlich Willkommen</h3>
      <div class="horizontal-divider"></div>

    <div class="flex flex-wrap overflow-hidden lg:mt-8">
      <div class="w-full lg:w-2/3 lg:pr-12 overflow-hidden content text-base md:text-xl lg:text-2xl">
          <h1>Die Firma Döbele Heizungsservice bietet Ihnen kompetenten und zuverlässigen 
          Gasheizungs&shy;kundendienst.</h1>
          <br>
          <p>Wir sind speziell auf die Wartung und Reparatur von Gasheizungen ausgerichtet.
          Regelmäßige Wartung ist die Voraussetzung für einen sicheren und störungsfreien Betrieb. Gleichzeitig wird die effiziente Nutzung des Brennstoffes und somit ein schadstoffarmer Betrieb gewährleistet.</p>
          <p>Im Störfall stehen wir ganzjährig zur Verfügung.</p>
          <br>
          <p>Als „SHK-Fachkraft für Hygiene und Schutz des Trinkwassers“ bieten wir umfassende Beratungen zum Thema Trinkwasser. Des Weiteren installieren und überprüfen wir Weichwasser- bzw. Enthärtungsanlagen.</p>

          <div class="text-black border-0 pt-4 rounded text-base md:text-xl lg:hidden">
           <h3 class="text-base md:text-xl lg:mt-4">Wir betreuen unter anderem die Fabrikate:</h3>
            Vaillant, Weishaupt, Buderus, Junkers, Viessmann, Brötje, Wolf, Remeha
          </div>

          <div class="w-full py-4 mt-0 hidden md:block">
                <div class="inline-block mr-2 md:mb-2">
                   <button type="button" class="focus:outline-none text-white text-md py-3 px-6 rounded-md bg-ld">Unsere Telefonnummer: 07525 9459901</button>
                </div>
          </div>

          <div class="md:w-1/2 mx-auto">
            <div class="rounded-md shadow mb-4 mt-4 md:hidden">
              <a href="tel:075259459901" class="w-full flex items-center justify-center px-8 py-3 border border-transparent text-md font-medium rounded-md text-white font-black bg-ld md:py-3 md:text-lg md:px-8">
                &#9742;  Jetzt anrufen
              </a>
            </div>
          </div>

      </div>
      
      <div class="w-full lg:w-1/3 overflow-hidden">
      <!-- Column Content -->
          <div class='max-w md:mt-0 p-4 bg-gray-100 shadow-sm lg:rounded overflow-hidden mx-auto text-base md:text-xl lg:text-xl'>
            <div class="contactblock"><b>Kontakt</b></div>
            <div class="addressblock">Döbele Heizungsservice<br>
              Lothar Döbele <br>
              Kornhausstraße 10<br>
              88326 Aulendorf<br>
              <br>
              Tel. 07525 9459901<br>
              E-Mail: mail@doebele-info.de
            </div>
          </div>
      </div>
    </div>

    <div class="w-full mt-2 md:mt-2 lg:mt-0 md:pl-0">
      <div class="text-black  border-0 rounded text-base md:text-xl lg:text-2xl hidden lg:block">
      <h3 class="text-lg md:text-2xl">Wir betreuen unter anderem die Fabrikate:</h3>
      Vaillant, Weishaupt, Buderus, Junkers, Viessmann, Brötje, Wolf, Remeha
      </div>
    </div>

</section>

<!-- Testimonials -->
    <section class="bg-gray-100 mt-4 lg:mt-8">
      <div class="container mx-auto px-3 pt-4 pb-6">
        <h3 class="text-2xl md:text-4xl font-bold">
          Service
        </h3>
        <div class="horizontal-divider"></div>
        
        <div class="flex flex-wrap mt-6">
     
          <div class="flex lg:flex-1 w-full lg:w-1/2 lg:px-2 mb-4">
            
            <!-- Article -->
            <article class="overflow-hidden shadow-lg bg-white">

                <a href="#">
                    <img class="block h-auto w-full object-cover" src="../assets/images/kundendienst_team.jpg" alt="Unser Team in Aulendorf, Bad Waldsee, Weingarten und Ravensburg">
                </a>

                <header class="p-2 md:p-4">
                    <h1 class="text-2xl md:text-4xl text-ld">
                       Zuverlässiger Gasheizungskundendienst
                    </h1>
                </header>

                <footer class="items-center justify-between leading-none p-2 md:pl-4">
                    <p class="text-base md:text-xl">
                    Gasheizungen sollten jährlich von einem Fachbetrieb gewartet werden. Wir überprüfen und reinigen Ihre Gasheizung.
                    </p>    
                    <ul class="mt-2 text-base md:text-xl list-none list-inside">
                      <li class="px-2 py-2"><span class="text-green-500">&#10003;</span> Effiziente Energieausnutzung</li>
                      <li class="px-2 py-2"><span class="text-green-500">&#10003;</span> Keine unnötige Ausfälle</li>
                      <li class="px-2 py-2"><span class="text-green-500">&#10003;</span> Verlängerte Lebensdauer ihrer Heizung</li>
                      <li class="px-2 py-2"><span class="text-green-500">&#10003;</span> Notdienst 365 Tage/ Jahr</li>
                    </ul>
                </footer>

            </article>
            <!-- END Article -->

           </div>

           <div class="flex lg:flex-1 w-full lg:w-1/2 lg:px-2 mb-4">
           
            <!-- Article -->
            <article class="overflow-hidden shadow-lg bg-white">

                <a href="#">
                    <img class="block h-auto w-full object-cover" src="../assets/images/service.jpg" alt="Wir bieten Dienstleistungen rund um das Thema Heizung an">
                </a>

                <header class="justify-between leading-tight p-2 md:p-4">
                    <h1 class="text-2xl md:text-4xl text-ld">
                    Zusätzliche Leistungen
                    </h1>
                </header>

                <footer class="items-center justify-between leading-none p-2 md:pl-4">
                  
                      <p class="text-base md:text-xl">
                      Wir kümmern uns um viele weiteren Themen:
                      </p>  

                      <ul class="mt-2 text-base md:text-xl list-none list-inside">
                      <li class="px-2 py-2"><span class="text-green-500">&#10003;</span> Installation Enthärtungsanlagen</li>
                      <li class="px-2 py-2"><span class="text-green-500">&#10003;</span> Wartung von thermischen Solaranlagen</li>
                      <li class="px-2 py-2"><span class="text-green-500">&#10003;</span> Dichtheitskontrolle von Gasleitungen</li>
                      <li class="px-2 py-2"><span class="text-green-500">&#10003;</span> Ansprechpartner für sonstige Heizungsthemen</li>
                    </ul>  
                </footer>

            </article>
            <!-- END Article -->
           
          </div>
          
        </div>


      </div>
    </section>




  <div class="mt-0 lg:mt-8 px-3 pt-2 hidden md:block"> 
    <section class="kontaktmoeglichkeiten">
        <h3 class="text-1xl md:text-2xl lg:text-4xl">Kontaktmöglichkeiten</h3>
    <div class="horizontal-divider"></div>
     <div class="text-base md:text-xl lg:text-2xl lg:mt-8">
        <h3>Telefon:</h3>
        <h3><a href="tel:+4975259459901" style="text-decoration: none;">07525 9459901</a></h3>
        <br>
        <h3>E-Mail:</h3>
        <h3>mail@doebele-info.de</h3>
        <br>
        <h3>Adresse:</h3>
        <h4>Döbele Heizungsservice<br>
        Kornhausstraße 10<br>
        88326 Aulendorf<br>
        </h4>
    </div>
    </section>
 
  </div>

        <div class="md:hidden px-4">
           <div class="rounded-md shadow mb-4 mt-4 lg:hidden">
              <a href="tel:075259459901" class="w-full flex items-center justify-center px-8 py-3 border border-transparent text-md font-medium rounded-md text-white font-black bg-ld md:py-3 md:text-lg md:px-8">
                &#9742;  Jetzt anrufen
              </a>
            </div>
        </div>

  </div>
</template>

<style lang="css">

.bsry {
   display: none;
  }

</style>

<script>
// @ is an alias to /src


export default {
  name: 'Home',
  metaInfo: {
    title: 'Döbele Gasheizungsservice in Aulendorf, Bad Waldsee, Ravensburg',
    meta: [
      { name: 'description', content: 'Döbele Gasheizungsservice - Wir sind speziell auf die Wartung und Reparatur von Gasheizungen ausgerichtet.' },
      { name: 'keywords', content: 'Kundendienst, Wartung, Reparatur, Gasheizung, Heizungswartung, Heizung Kundendienst, Kundendienst Heizung, Heizung Reparatur, Bad Waldsee, Ravensburg, Aulendorf, Bad Wurzach, Oberschwaben' }, 
    ],
    link: [
        { rel: 'canonical', href: "/" }
      ]
    },
}
</script>
